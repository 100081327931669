export const get_methods = {
    getRateList: {
        uri: "?_url=/vedexx",
        name: "getRateList"
    },
    getReferenceData: {
        uri: "?_url=/vedexx",
        name: "getReferenceData"
    },
    
    getSearchParamsHistory: {
        uri: "?_url=/vedexx",
        name: "getSearchParamsHistory"
    },
    
    getMinRateJson: {
        uri: "?_url=/vedexx",
        name: "getMinRateJson"
    },
    getArticleBodyJson: {
        uri: "?_url=/vedexx",
        name: "getArticleBodyJson"
    },
    getRecomendations: {
        uri: "?_url=/vedexx",
        name: "getRecomendations"
    },
    getSiteMenuJson: {
        uri: "?_url=/vedexx",
        name: "getSiteMenuJson",
    },
    getRateById: {
        uri: "?_url=/vedexx",
        name: "getRateById"
    },
    auth: {
        uri: "?_url=/vedexx",
        name: "auth"
    },
    registerByKey:{
        uri: "?_url=/vedexx",
        name: "registerByKey"
    },
    
    getUserData: {
        uri: "?_url=/vedexx",
        name: "getUserData"
    },
    
    getNakInnList: {
        uri: "?_url=/vedexx",
        name: "getNakInnList"
    },
    
    checkKey: {
        uri: "?_url=/vedexx",
        name: "checkKey"
    },
    
    setCompleteDocRequest: {
        uri: "?_url=/vedexx",
        name: "setCompleteDocRequest"
    },
    
    addClientRequest: {
        uri: "?_url=/vedexx",
        name: "addClientRequest"
    },
    
    setRateAnalizeRequest: {
        uri: "?_url=/vedexx",
        name: "setRateAnalizeRequest"
    },
    
    getCopyOrderAnalogRate: {
        uri: "?_url=/vedexx",
        name: "getCopyOrderAnalogRate"
    },
    
    getNotPayedInvoices: {
        uri: "?_url=/vedexx",
        name: "getNotPayedInvoices"
    },
    
    getClientRequestsList: {
        uri: "?_url=/vedexx",
        name: "getClientRequestsList"
    },
    
    getRegisterLink:{
        uri: "?_url=/vedexx",
        name: "generateLink"
    },
    
    getServiceNews: {
        uri: "?_url=/vedexx",
        name: "getServiceNews"
    },
    
    getHotNewsList: {
        uri: "?_url=/vedexx",
        name: "getHotNewsList"
    },
    
    setFeedBack: {
        uri: "?_url=/vedexx",
        name: "setFeedBack"
    },
    
    getArchOrdersList: {
        uri: "?_url=/cabinet",
        name: "getArchOrdersList"
    },
    
    getBookingRequestComments: {
        uri: "?_url=/vedexx",
        name: "getBookingRequestComments"
    },
    
    getThreeBestRateParams: {
        uri: "?_url=/vedexx",
        name: "getThreeBestRateParams"
    },
    
    setActiveThreeBestRateParams: {
        uri: "?_url=/vedexx",
        name: "setActiveThreeBestRateParams"
    },
    
    setNewThreeBestRateParams: {
        uri: "?_url=/vedexx",
        name: "setNewThreeBestRateParams"
    },
    
    getClientReviewsList: {
        uri: "?_url=/vedexx",
        name: "getClientReviewsList"
    },
    
    getNewsBody: {
        uri: "?_url=/vedexx",
        name: "getNewsBody"
    },
    
    getOurTeam: {
        uri: "?_url=/vedexx",
        name: "getOurTeam"
    },
    
    getRateByKey: {
        uri: "?_url=/vedexx",
        name: "getRateByKey"
    },
    
    getRateListAlert: {
        uri: "?_url=/vedexx",
        name: "getRateListAlert",
    },
    
    getRateStatisticByKey: {
        uri: "?_url=/vedexx",
        name: "getRateStatisticByKey"
    },
    
    getRoute: {
        uri: "?_url=/autodispetcher",
        name: "getRoute"
    },
    
    getAlternativeRoute: {
        uri: "?_url=/vedexx",
        name: "getAlternativeRoute"
    },
    
    getClientGoodsQuestionnaire: {
        uri: "?_url=/vedexx",
        name: "getClientGoodsQuestionnaire"
    },
    
    getRouteExt: {
        uri: "?_url=/autodispetcher",
        name: "getRouteExt"
    },
    
    getCityList: {
        uri: "?_url=/autodispetcher",
        name: "getCityList"
    },
    
    getAutoServiceInfo: {
        uri: "?_url=/vedexx",
        name: "getAutoServiceInfo"
    },
    
    getAutoServiceInfoRegion: {
        uri: "?_url=/vedexx",
        name: "getAutoServiceInfoRegion"
    },
    
    getApproximatePrice: {
        uri: "?_url=/vedexx",
        name: "getApproximatePrice"
    },
    
    getInshuranceCost: {
        uri: "?_url=/vedexx",
        name: "getInshuranceCost"
    },
    
    getTransporterInfoById: {
        uri: "?_url=/vedexx",
        name: "getTransporterInfoById"
    },
    
    setContragentRateInfo: {
        uri: "?_url=/vedexx",
        name: "setContragentRateInfo"
    },
    
    getSettingByName: {
        uri: "?_url=/vedexx",
        name: "getSettingByName"
    },
    
    getSecurityServiceInfo: {
        uri: "?_url=/vedexx",
        name: "getSecurityServiceInfo"
    },
    
    getCompanyByInn: {
        uri: "?_url=/getcompanybyinn/"
    },
    
    getBankByBik: {
        uri: "?_url=/getbankbybik/"
    },
    
    getClientCommission: {
        uri: "?_url=/vedexx",
        name: "getClientCommission"
    },
    
    getAdditionalExpensesRate: {
        uri: "?_url=/vedexx",
        name: "getAdditionalExpensesRate"
    },
    getShipScheduleByKey: {
        uri: "?_url=/vedexx",
        name: "getShipScheduleByKey"
    },
    getComplexRateList:{
        uri: "?_url=/vedexx",
        name: "getComplexRateList"
    },
    getComplexShipSchedule:{
        uri: "?_url=/vedexx",
        name: "getComplexShipSchedule"
    },
    getComplexAdditionalExpences:{
        uri: "?_url=/vedexx",
        name: "getComplexAdditionalExpences"
    },
    getCompareRateList:{
        uri: "?_url=/vedexx",
        name: "getCompareRateList"
    },
    getRequestListData: {
        uri: "?_url=/vedexx",
        name: "getRequestListData"
    },
    
    getServiceInfo: {
        uri: "?_url=/vedexx",
        name: "getServiceInfo"
    },
    
    getServiceDelayInfo: {
        uri: "?_url=/vedexx",
        name: "getServiceDelayInfo"
    },
    
    getOrder: {
        uri: "?_url=/cabinet",
        name: "getOrder",
    },
    
    getOrderMarksTemplate: {
        uri: "?_url=/vedexx",
        name: "getOrderMarksTemplate",
    },
    
    setOrderMark: {
        uri: "?_url=/vedexx",
        name: "setOrderMark",
    },
    
    getClientReferenceData: {
        uri: "?_url=/vedexx",
        name: "getClientReferenceData",
    },
    
    getVedexxFaq: {
        uri: "?_url=/vedexx",
        name: "getVedexxFaq",
    },
    
    getActiveOrdersList: {
        uri: "?_url=/cabinet",
        name: "getActiveOrdersList",
    },
    getCustomsRequestComments: {
        uri: "?_url=/vedexx",
        name: "getCustomsRequestComments"
    },
    getClientRequestComments: {
        uri: "?_url=/vedexx",
        name: "getClientRequestComments"
    },
    getOrdersFilter: {
        uri: "?_url=/cabinet",
        name: "getOrdersFilter",
    },
    getFilterList: {
        uri: "?_url=/filters",
        name: "getFilterList"
    },
    getFileByPath: {
        uri: "?_url=/files",
        name: "getfilebypath"
    },
    ftpposttest:{
        uri: "?_url=/ftpposttest",
        name: "ftpposttest"
    },
    
    getFinDetailsByOrderId: {
        uri: "?_url=/vedexx",
        name: "getFinDetailsByOrderId"
    },
    
    getAdditionalExpensesRateByOrdId: {
        uri: "?_url=/vedexx",
        name: "getAdditionalExpensesRateByOrdId"
    },
    
    getInfoByContainer: {
        uri: "?_url=/ed22",
        name: "GetInfoByContainer"
    },
    getProviderInfo: {
        uri: "?_url=/ed22",
        name: "GetProviderInfo"
    },
    getAltaInfo: {
        uri: "?_url=/ed22",
        name: "GetAltaInfo"
    },
    getPortByContNum: {
        uri: "?_url=/ed22",
        name: "getPortByContNum"
    },
};

export const set_methods = {
    setRateRequest: {
        uri: "?_url=/vedexx",
        name: "setRateRequest"
    },
    setClientCustomsRequestComment: {
        uri: "?_url=/vedexx",
        name: "setClientCustomsRequestComment"
    },
    
    setClientGoodsQuestionnaire: {
        uri: "?_url=/vedexx",
        name: "setClientGoodsQuestionnaire"
    },
    
    setUserDocumentStatus:{
        uri: "?_url=/vedexx",
        name: "setUserDocumentStatus"
    },
    
    setUpdateOrderRequest:{
        uri: "?_url=/vedexx",
        name: "setUpdateOrderRequest"
    },
    setClientRequestComment: {
        uri: "?_url=/vedexx",
        name: "setClientRequestComment"
    },
    setPrefRequestData: {
        uri: "?_url=/vedexx",
        name: "setPrefRequestData"
    },
    
    setClientCommission: {
        uri: "?_url=/vedexx",
        name: "setClientCommission"
    },
    addContractFile: {
        uri: "?_url=/vedexx",
        name: "addContractFile"
    },
    
    setFilesToOrder: {
        uri: "?_url=/vedexx",
        name: "setFilesToOrder"
    },
    
    setNewContragent: {
        uri: "?_url=/vedexx",
        name: "setNewContragent"
    },
    
    check: {
        uri: "?_url=/vedexx",
        name: "check"
    },
    
    getSettingByName: {
        uri: "?_url=/vedexx",
        name: "getSettingByName"
    },
    
    setRateRequestMessage:{
        uri: "?_url=/vedexx",
        name: "setRateRequestMessage"
    },
    
    setNotifyStatus:{
        uri: "?_url=/vedexx",
        name: "setNotifyStatus"
    },
    
    setCommentVizited: {
        uri: "?_url=/vedexx",
        name: "setCommentVizited"
    },
    
    setMultiCommentVizited: {
        uri: "?_url=/vedexx",
        name: "setMultiCommentVizited"
    }
};

export const reference_list = {
    ClientVDRefList: {
        name: "clnt_vd_ref_company",
        items: []
    },
    
    ClientVDRefDeliveryTerms: {
        name: "clnt_vd_ref_delivery_terms",
        items: []
    },
    
    ClientVDRefUnitTypes: {
        name: "lgst_cont_types",
        items: []
    },
    
    lgst_place_from_wg: {
        name: "lgst_place_from_wg",
        items: []
    },
    
    lgst_place_to_wg: {
        name: "lgst_place_to_wg",
        items: []
    },
    
    lgst_alarm_class: {
        name: "lgst_place_to_wg",
        items: []
    },
    
    ClientVDRefPlaceFrom: {
        name: "places",
        items: []
    },
    ClientVDRefPlaceTo: {
        name: "dsplaces",
        items: []
    },
    ClientVDRefGoods: {
        name: "goods",
        items: []
    },
    ClientVDRefCustoms: {
        name: "ref_to_places",
        items: []
    },
    ClientVDRefDeliveryTypes: {
        name: "ref_shipping_types",
        items: []
    },
    ClientVDRefTaxRates: {
        name: "ref_tax_rate_prc_rate_request",
        items: []
    },
    ClientVDRefPorts: {
        name: "ports",
        items: []
    },
    ClientVDRefClients: {
        name: "clientvdrefclients",
        items: []
    },
    ClientVDRefClientsCommission:{
        name: 'clientvdrefclientscommission',
        items: []
    },
    ClientVDRefCurrency:{
        name: 'ref_freight_currency',
        items: []
    },
    LgstLineList:{
        name: 'lgst_line_list',
        items: []
    },
};

