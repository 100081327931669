import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import en from "vuetify/es5/locale/en";
import it from "vuetify/es5/locale/it";
import de from "vuetify/es5/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
    locales: [en, it, de],
    lang: {
        current: 'ru',
    },
});
