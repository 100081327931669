import platformIcon from '../../assets/img/icons/platform-grey.svg';
import sourceIcon from '../../assets/img/icons/source-grey.svg';
import contactIcon from '../../assets/img/icons/contact-grey.svg';
import searcIcon from '../../assets/img/icons/search-blue.svg';
export const menuList = [
  {
    title: 'Поиск ставки',
    link: '#searchRates',
    target: '_self',
    icon: searcIcon,
    children: []
  },
  
  {
    title: 'О платформе',
    link: '',
    icon: platformIcon,
    children: [
      {
        title: 'Что такое Vedexx?',
        link: '#whatVedexxIs',
        target: '_self',
      },
      {
        title: 'Видео-инструкции',
        link: '#videoInstructions',
        target: '_self',
      },
      {
        title: 'Отзывы',
        link: '#reviews',
        target: '_self',
      },
    ]
  },
  
  {
    title: 'Ресурсы',
    link: 'https://project.vedexx.ru/blog',
    icon: sourceIcon,
    children: [
      {
        title: 'Блог',
        link: 'https://project.vedexx.ru/blog',
        target: '_blank',
      },
    ]
  },
  
  {
    title: 'Контакты',
    link: '#contact',
    target: '_self',
    icon: contactIcon,
    children: []
  }
]
