<script>
import { bus } from "../../router/bus";

export default {
  name: "ContactForm.vue",
  
  props: {
    rate: {
      type: [Object, Array],
      required: false
    }
  },
  
  data: () => ({
    user_email: null,
    message: null,
    subject: null,
    current_href: null,
    
    emailRules: [
      v => !!v || 'Укажите email',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Похоже, что введенный адрес некорректен...'
      },
    ],
    
    textRules: [
      v => !!v || 'Укажите обращение',
    ],
  }),
  
  mounted() {
    if (this.$_getsetting('email')) {
      this.user_email = this.$_getsetting('email')
    }
    
    if (this.rate) {
      this.current_href = 'https://vedexx.com/RatePage?key=' +
        this.rate.key_orig + '&on_date=' +
        this.rate.on_date + '&cid=' + this.$_getsetting('client_id')
    } else {
      this.current_href = location.href
    }
  },
  
  methods: {
    validate() {
      let result = this.$refs.form.validate()
      if (result) {
        this.sendContactForm()
      }
    },
    
    sendContactForm() {
      this.$postapi(this.$address + this.$getters.setFeedBack.uri, {
        method: this.$getters.setFeedBack.name,
        bcc: '',
        cc: '',
        // rate_key: this.rate_key ? this.rate_key : '',
        to: this.$_getsetting('manager_email') ? this.$_getsetting('manager_email') : 'help@vedexx.com',
        subject: this.subject,
        type: 'email',
        body: 'Клиент: ' + this.$_getsetting('clnt_name') + `<p></p>` +
          'почта: ' + this.user_email + `<br/>` +
          'текст: ' + this.message + `<p></p>` +
          'ссылка: ' + this.current_href,
        atachments: [],
      }).then(data => {
        if (data.error == 0) {
          this.clearForm()
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message + ' Мы свяжемся с вами в ближайшее время'
          });
          this.clearForm()
          this.$emit('close')
        }
      });
    },
    
    clearForm() {
      this.message = ''
      this.$refs.form.resetValidation()
    }
  }
}

</script>

<template>
  <div>
    <div class="form-title">
      Форма для связи
    </div>
    <v-form
      ref="form"
      class="form"
      @submit.prevent="validate"
    >
      <v-text-field
        dense
        v-model="user_email"
        label="Ваш email"
        class="form-input"
        outlined
        :rules="emailRules"
        color="var(--main-orange)"
      />
    
      <v-textarea
        v-model="message"
        label="Ваше сообщение"
        class="form-input"
        outlined
        no-resize
        :rules="textRules"
        color="var(--main-orange)"
      />
    
      <v-btn
        class="form-btn"
        dark
        width="100%"
        color="var(--main-bg-color)"
        type="submit"
      >
        Отправить
      </v-btn>
    
      <div class="form-link">или напишите нам в техническую поддержку
        <a href="mailto:help@vedexx.com">help@vedexx.com</a>
      </div>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.form {
  max-width: 100%;
  width: 100%;
  
  @media (max-width: $mobile-width) {
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0;
    padding: 0;
  }
  
  &-title {
    display: none;
    font-size: 20px;
    margin: 30px 0 -5px;
    font-weight: 700;
  
    @media (max-width: 600px) {
      display: block;
    }
  }
  
  &-input {
    
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  
  &-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.00892857em;
    text-transform: lowercase;
    
    @media (max-width: $mobile-width) {
      width: 100% !important;
    }
  }
  
  &-link {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--bg-dark);
  }
}


.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

a {
  //color: black !important;
  text-decoration: underline;
  color: var(--bg-dark) !important;
  //margin-left: 4px;
  
  &:hover {
    text-decoration: underline;
  }
}
</style>
