<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-if="showModal" class="app-modal">
      <slot></slot>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 600px) {
    z-index: 999;
  }
}

slot {
  width: 100%;
}
</style>
