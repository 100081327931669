<template>
  <v-app>
    <component :is="layout">
      <router-view/>
      <notifications position="top right" class="mt-notify" width="25%">
        <template slot="body" slot-scope="props">
          <div
            class="vue-notify"
            :class="'vue-notify_' + props.item.type"
          >
            <div class="mt-notify-title flex justify-space-between">
              {{props.item.title}}
              <v-icon size="20" class="ma-1" color="white" @click="props.close">mdi-close</v-icon>
            </div>
            <div class="mt-notify-text">
              {{props.item.text}}
            </div>
          </div>
        </template>
      </notifications>
    </component>
  </v-app>
</template>

<script>
import DefaultLayout from "./components/layouts/DefaultLayout";
import DarkLayout from "./components/layouts/DarkLayout";
import LightLayout from "./components/layouts/LightLayout";
import EmptyLayout from "./components/layouts/EmptyLayout";
import RatePageLayout from "./components/layouts/RatePageLayout.vue";

export default {
  name: 'App',

  components: {
    DefaultLayout,
    DarkLayout,
    LightLayout,
    EmptyLayout,
    RatePageLayout
},
  
  mounted() {
    setTimeout(() => {
      this.sendYandexLink()
    }, 2000);
  },
  
  computed: {
    layout() {
      return this.$route.meta.layout || "DarkLayout";
    },
  },
  
  watch: {
    '$route.query': {
      handler() {
        this.$metrika.hit(this.$route.fullPath)
      },
      deep: true
    },
  },
  
  methods: {
    sendYandexLink() {
      this.$metrika.hit(this.$route.fullPath)
    },

    checkPage() {
      // Проверьте, находитесь ли вы на определенной странице
      if (this.$route.name === 'RatePage') {
        this.addChatStyle();
      }
    },

    addChatStyle() {
      // Дождитесь, пока элемент с классом .online-chat-root будет доступен в DOM
      this.$nextTick(() => {
        const chatElement = document.querySelector('.online-chat-root');
        if (chatElement) {
          chatElement.style.setProperty('display', 'none', 'important');
        }
      });
    },
    
    getNotifyClass(className) {
      return 'vue-notification_' + className
    }
  }

};
</script>

<style lang="scss">

.vue-notify {
  padding: 20px 20px;
  margin: 0 5px 5px;
  font-size: 12px;
  color: #ffffff;
  border-left: 5px solid black;
  background: #f48a06;
  border-left-color: #d37601;
  
  @media (max-width: 600px) {
    //min-height: 15vh;
    height: auto;
    width: 100% !important;
    padding: 20px 15px;
    border-radius: 10px;
  }
  
  &_warn {
     background: #f48a06;
     border-left-color: #d37601;
   }
  
  &_error {
     background: #E54D42;
     border-left-color: #B82E24;
   }
  
  &_success {
     background: #42A85F;
     border-left-color: #378c4f;
   }
}

.mt-notify{
  margin-top: 0.4%;
  margin-right: 0.4%;
  border-radius: 0.02vw;
  color: #FFFFFF;
  
  @media (max-width: 600px) {
    border-radius: 10px;
    width: 100% !important;
  }
}

.vue-notification-group {

}

.dx-scrollbar-horizontal{
  height: 15px !important;
}
.dx-scrollable-scroll{
  height: 15px !important;
}

.mt-notify-text {
  font-size: 0.8vw;
  
  @media (max-width: 600px) {
   font-size: 12px;
  }
}

.mt-notify-title{
  font-size: 1vw;
  font-weight: 600;
  display: flex;
  width: 100%;
  
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.dx-widget{
  font-family: 'Manrope', sans-serif !important;
}
</style>
